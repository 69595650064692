import React from 'react';
import {connect} from "react-redux";
import {reduxForm} from 'redux-form';
import {Button, Alert} from 'react-bootstrap';
import queryString from 'query-string';

import {productsList, additionalAnimalPrice} from '../../constant';
import {submitPayment} from "../../actions/paymentActionCreators";


class FreeForm extends React.Component {

  getPaymentInfo() {
    const {payment, questions} = this.props;
    if (!payment.checkout && (payment.productId !== undefined && payment.productId !== null && [0,1,2].includes(payment.productId))) {
      return {
        ...this.props.payment,
        checkout: {
          subtotal: productsList[payment.productId].price,
          orderTotal: productsList[payment.productId].price + additionalAnimalPrice * (questions.additionalAnimals ? questions.additionalAnimals.length : 0),
        }
      };
    }
    return payment;
  }

  handleSubmit = (data) => {
    const { userInfo, questions } = this.props;
    const parsed = queryString.parse(location.search);
    const payment = this.getPaymentInfo();
    let animals = questions.additionalAnimals.slice(0);
    animals.unshift(questions.animalInfo);

    return this.props.submitPayment({
      discount_token: parsed.discount_token,
      amount: payment.checkout.orderTotal,
      product: productsList[payment.productId].ref,
      patient: userInfo,
      animals: animals,
      billing_info: data,
      condition: JSON.stringify(this.props.questions.userQuestion)
    });
  }

  render() {
    const {handleSubmit, submitting} = this.props;
    const payment = this.getPaymentInfo();

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>

        {payment.errors && payment.errors.global &&
        <Alert variant="danger">
          <strong>{payment.errors.global}</strong>
        </Alert>
        }
        <Button
          variant="primary"
          type="submit"
          disabled={submitting}>
          {
            submitting ?
              <div>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                &nbsp;Processing
              </div>
              :
              "Submit Request"
          }
        </Button>
      </form>
    )
  }
}

const createReduxForm = reduxForm({form: 'payment'})

FreeForm = createReduxForm(FreeForm)

function bindAction(dispatch) {
  return {
    submitPayment: (data) => dispatch(submitPayment(data))
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment: state.payment,
    questions: state.questions,
  }
}

export default connect(mapStateToProps, bindAction)(FreeForm);
